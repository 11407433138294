import { bootstrapAppHistory } from './history';
import { bootstrapAppLogger } from './logger';
import { bootstrapAppRegistry } from './registry';
import { bootstrapAppTranslation } from './translation';

type BootstrapModuleExport<M> = {
  default: () => M;
};

function yieldToMain() {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}

const bootstrapModule = <P>(importer: () => Promise<BootstrapModuleExport<P>>) => {
  return function lazyImport() {
    return new Promise((resolve) => {
      importer().then(({ default: bootstrap }) => {
        const r = bootstrap();
        resolve(r);
      });
    });
  };
};

export const bootstrapAppModules = async () => {
  bootstrapAppRegistry();
  bootstrapAppLogger();
  bootstrapAppHistory();
  bootstrapAppTranslation();
};
