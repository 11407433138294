import { getWindowLocation } from './browser';
import { getGeneralSettingsFromAdmin } from './settings';

export const isCollectionPage = () => {
  return getGeneralSettingsFromAdmin().page === 'collection';
};

export const isSearchPage = () => {
  return getGeneralSettingsFromAdmin().page === 'search';
};

export const isPageType = () => {
  return window.location.pathname.indexOf('/collections/types') > -1 ? true : false;
};

export const isVendorPage = () => {
  return window.location.pathname.indexOf('/collections/vendors') > -1 ? true : false;
};

export const isConvertCurrenciesOnFrontEnd = (roundRules: any) => {
  return roundRules && typeof roundRules == 'object';
};

export const isTagPage = (tags?: string[]) => {
  if (tags && tags.length > 0) return true;
  return false;
};

export const isProductPage = () => {
  return getGeneralSettingsFromAdmin().page === 'product';
};

export const isCartPage = () => {
  return getGeneralSettingsFromAdmin().page === 'cart';
};

export const isHomePage = () => {
  return getGeneralSettingsFromAdmin().page === 'index';
};

/**
 * Test for XSS in URL before init filter
 * @return {boolean}
 */
export const isBadSearchTerm = (term: string) => {
  if (typeof term == 'string') {
    const domEvents = [
      'onabort',
      'popstate',
      'afterprint',
      'beforeprint',
      'beforeunload',
      'blur',
      'canplay',
      'canplaythrough',
      'change',
      'click',
      'contextmenu',
      'copy',
      'cut',
      'dblclick',
      'drag',
      'dragend',
      'dragenter',
      'dragleave',
      'dragover',
      'dragstart',
      'drop',
      'durationchange',
      'ended',
      'error',
      'focus',
      'focusin',
      'focusout',
      'fullscreenchange',
      'fullscreenerror',
      'hashchange',
      'input',
      'invalid',
      'keydown',
      'keypress',
      'keyup',
      'load',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'mousedown',
      'mouseenter',
      'mouseleave',
      'mousemove',
      'mouseover',
      'mouseout',
      'mouseout',
      'mouseup',
      'offline',
      'online',
      'pagehide',
      'pageshow',
      'paste',
      'pause',
      'play',
      'playing',
      'progress',
      'ratechange',
      'resize',
      'reset',
      'scroll',
      'search',
      'seeked',
      'seeking',
      'select',
      'show',
      'stalled',
      'submit',
      'suspend',
      'timeupdate',
      'toggle',
      'touchcancel',
      'touchend',
      'touchmove',
      'touchstart',
      'unload',
      'volumechange',
      'waiting',
      'wheel',
    ];
    const potentialEventRegex = new RegExp(domEvents.join('=|on'));
    const countOpenTag = (term.match(/</g) || []).length;
    const countCloseTag = (term.match(/>/g) || []).length;
    const isAlert = (term.match(/alert\(/g) || []).length;
    const isConsoleLog = (term.match(/console\.log\(/g) || []).length;
    const isExecCommand = (term.match(/execCommand/g) || []).length;
    const isCookie = (term.match(/document\.cookie/g) || []).length;
    const isJavascript = (term.match(/j.*a.*v.*a.*s.*c.*r.*i.*p.*t/g) || []).length;
    const isPotentialEvent = potentialEventRegex.test(term);
    if (
      (countOpenTag > 0 && countCloseTag > 0) ||
      countOpenTag > 1 ||
      countCloseTag > 1 ||
      isAlert ||
      isConsoleLog ||
      isExecCommand ||
      isCookie ||
      isJavascript ||
      isPotentialEvent
    ) {
      return true;
    }
  }
  return false;
};

export const isBadUrl = (url: string, ignoreCharacterURL?: (url: string) => string) => {
  try {
    if (!url) {
      url = getWindowLocation().search;
    }

    url = decodeURIComponent(url);

    if (ignoreCharacterURL) {
      url = ignoreCharacterURL?.(url);
    }

    const urlParams = url.split('&');
    let isXSSUrl = false;
    if (urlParams.length > 0) {
      for (let i = 0; i < urlParams.length; i++) {
        const param = urlParams[i];
        isXSSUrl = isBadSearchTerm(param);
        if (isXSSUrl) break;
      }
    }
    return isXSSUrl;
  } catch {
    return true;
  }
};
