import './styles.scss';

import { bootstrap, getInstalledFeatures } from '@boost-sd/app';

import themeSettings from './defaultSettings.json';

(function ensureSettings() {
  window.boostSDAppConfig.themeSettings = Object.assign(
    {},
    themeSettings,
    window.boostSDAppConfig?.themeSettings || {}
  );
})();

bootstrap().then(() => {
  const { filter, search, recommendation, quickview, cart } = getInstalledFeatures();

  if (filter) {
    import(/* webpackChunkName: "filter" */ './blocks/filter');
  }

  if (search) {
    import(/* webpackChunkName: "search" */ './blocks/search');
  }

  if (recommendation) {
    import(/* webpackChunkName: "recommendation" */ './blocks/recommendation');
  }

  // Low priority resources
  setTimeout(() => {
    if (cart) {
      import(/* webpackChunkName: "cart" */ './cart').then(({ initCart }) => {
        initCart();
      });
    }

    if (quickview) {
      import(/* webpackChunkName: "quickview" */ './blocks/quickview');
    }
  }, 500);
});
