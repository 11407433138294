import type { ComponentRegistryBootstrap } from '@boost-sd/components-registry';
import { componentRegistryBootstrap } from '@boost-sd/components-registry/bootstrap';

export const bootstrapAppRegistry = () => {
  if ('__BoostCustomization__' in window) {
    (Array.isArray(window.__BoostCustomization__)
      ? window.__BoostCustomization__
      : [window.__BoostCustomization__]
    ).forEach((bootstrap) => componentRegistryBootstrap(bootstrap as ComponentRegistryBootstrap));
  }
};

export default bootstrapAppRegistry;
