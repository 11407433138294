import { registryModule } from '@boost-sd/components-registry/registry';
import { Logger } from '@boost-sd/core-js/logger';
import { getAppConfig, getTAEFeatureFromAdmin } from '@utils';

export const bootstrapAppLogger = () => {
  const appConfig = getAppConfig();
  const taeFeatures = getTAEFeatureFromAdmin();
  let enabled = false;

  if ('logger' in appConfig) {
    enabled = appConfig.logger;
  } else if (appConfig.mode === 'development') {
    enabled = true;
  } else if (taeFeatures?.env) {
    enabled = taeFeatures.env === 'staging';
  }

  const logger = new Logger({
    enabled,
    namespace: 'default',
  });

  registryModule('Logger', logger);
};

export default bootstrapAppLogger;
