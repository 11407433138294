import { getTAEFeatureFromAdmin, getThemeSettingsFromAdmin } from './settings';
import { isCartPage, isCollectionPage, isHomePage, isProductPage, isSearchPage } from './validate';

export const getInstalledFeatures = () => {
  const { instantSearch, filterCollection, filterSearch, recommendation, recommendationWidgets } =
    getTAEFeatureFromAdmin() || {};

  // Instant search
  const enableSearch = instantSearch === 'installed';

  // Product grid filter
  const enableFilter =
    (isCollectionPage() && filterCollection === 'installed') ||
    (isSearchPage() && filterSearch === 'installed');

  // Recommendation
  const enableRecommendation =
    recommendation === 'installed' &&
    recommendationWidgets &&
    ((isHomePage() && 'home-page' in recommendationWidgets) ||
      (isCollectionPage() && 'collection-page' in recommendationWidgets) ||
      (isProductPage() && 'product-page' in recommendationWidgets) ||
      (isCartPage() && 'cart-page' in recommendationWidgets));

  // Quick View
  const productItems = getThemeSettingsFromAdmin()?.productItems;

  const hasThemeSettings = ['productImg', 'productInfo'].some((part) => {
    return (
      productItems?.[part]?.elements?.qvBtn ||
      (productItems?.[part]?.elements?.selectOptionBtn &&
        ['quickAddToCart', 'popup'].includes(
          productItems?.[part]?.elements?.selectOptionBtn?.action
        ))
    );
  });

  const hasListLayout =
    getThemeSettingsFromAdmin()?.additionalElements?.toolbar?.elements?.viewAs?.listType?.includes(
      'list'
    );

  const enableQuickView =
    (enableFilter || enableRecommendation) && (hasThemeSettings || hasListLayout);

  // Cart
  const enableCart = enableFilter || enableRecommendation;

  return {
    search: enableSearch,
    filter: enableFilter,
    recommendation: enableRecommendation,
    quickview: enableQuickView,
    cart: enableCart,
  };
};
