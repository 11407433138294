import type { BrowserHistory, Location } from 'history';
import { Action, createBrowserHistory } from 'history';
import { castArray, get, omit, pick, set } from 'lodash-es';
import stringify from 'qs/lib/stringify';
import { useCallback, useLayoutEffect, useRef, useSyncExternalStore } from 'react';
import { flushSync } from 'react-dom';

import { useLatest, useMountEffect } from '../react/hooks';
import type { Dict } from '../types';

const symbol = 'BoostSDBrowserHistory';
let history: BrowserHistory | null = null;
let historyStackIndex = 0;

export const setupAppHistory = () => {
  history = get(window, symbol) || createBrowserHistory();
  if (!history) return;

  set(window, symbol, history);

  historyStackIndex = 0;

  history.listen(({ action }) => {
    switch (action) {
      case 'POP': {
        if (historyStackIndex > 0) {
          historyStackIndex--;
        }
        break;
      }

      case 'REPLACE': {
        historyStackIndex = 0;
        break;
      }

      case 'PUSH': {
        historyStackIndex++;
        break;
      }
      default:
        break;
    }
  });

  return history;
};

export const getAppHistory = () => {
  if (!history) {
    throw new Error("AppHistory hasn't been initialized");
  }

  return history;
};

export const getQueryParamByKey = (key: string): string | string[] | null => {
  const history = getAppHistory();

  const urlParams = new URLSearchParams(history.location.search);

  return urlParams.get(key);
};

export const setQueryParam = (key: string, value: string | string[], replace?: boolean) => {
  const history = getAppHistory();

  const currentParamsState = getQueryParamsState();
  const searchString = `?${stringify(
    { ...currentParamsState, [key]: value },
    {
      indices: false,
    }
  )}`;

  replace ? history.replace(searchString) : history.push(searchString);
};

export const setQueryParams = (
  params: Dict,
  option?: {
    isShortenURL: boolean;
    /**
     * @description Use params as next query params - don't keep current params from URL
     */
    force?: boolean;
  }
) => {
  const history = getAppHistory();

  const currentParamsState = getQueryParamsState();

  const nextParams = option?.force
    ? params
    : {
        ...currentParamsState,
        ...params,
      };

  let searchString = `?${stringify(nextParams, {
    indices: false,
  })}`;

  if (option?.isShortenURL) {
    searchString = searchString.replace(/%2C/g, ',').replace(/%20/g, '+');
  }

  history.push(searchString);
};

export const removeQueryParam = (key: string) => {
  const history = getAppHistory();

  const currentParamsState = getQueryParamsState();

  delete currentParamsState[key];

  const searchString = `?${stringify(currentParamsState, {
    indices: false,
  })}`;

  history.push(searchString);
};

export const getQueryParamsState = (
  options: {
    singleAsArray?: boolean;
    pickKeys?: string[];
    excludeKeys?: string[];
    filter?: (key: string, value: string | string[]) => boolean;
    decodeKey?: boolean;
    decodeValue?: boolean;
  } = {
    singleAsArray: true,
    decodeKey: true,
    decodeValue: true,
  }
): Dict => {
  const history = getAppHistory();

  const { search } = history.location;

  const hashes = search.slice(search.indexOf('?') + 1).split('&');

  let params: Dict = {};

  if (hashes[0] === '') return params;

  const { filter, singleAsArray, pickKeys, excludeKeys, decodeKey, decodeValue } = options;

  hashes.forEach((hash) => {
    const [key, val] = hash.split('=');
    const decodedKey = decodeKey ? decodeURIComponent(key) : key;
    const decodedValue = decodeValue ? decodeURIComponent(val?.replace(/[+]/g, ' ')) : val;

    if (filter && !filter?.(key, decodedValue)) return;

    if (!params[decodedKey]) {
      if (singleAsArray) {
        params[decodedKey] = [decodedValue];
      } else {
        params[decodedKey] = decodedValue;
      }
    } else {
      if (Array.isArray(params[decodedKey])) {
        params[decodedKey].push(decodedValue);
      } else {
        params[decodedKey] = [params[decodedKey], decodedValue];
      }
    }
  });

  if (pickKeys) {
    params = pick(params, pickKeys);
  }

  if (excludeKeys) {
    params = omit(params, excludeKeys);
  }

  return params;
};

export const useHistory = () => {
  const history = getAppHistory();

  return useSyncExternalStore(history.listen, () => ({
    index: historyStackIndex,
    location: history.location,
    history,
  }));
};

export type ParamKeyValuePair = [string, string];

export type URLSearchParamsInit =
  | string
  | ParamKeyValuePair[]
  | Record<string, string | string[]>
  | URLSearchParams;

export type HistoryListener = (payload: {
  history: BrowserHistory;
  location: Location;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  paramsState: any;
}) => unknown;

export type HistoryEventListeners = {
  onReplace?: HistoryListener | HistoryListener[];
  onPush?: HistoryListener | HistoryListener[];
  onPop?: HistoryListener | HistoryListener[];
  onInit?: HistoryListener | HistoryListener[];
  all?: HistoryListener | HistoryListener[];
};

export type SyncQueryParamsProps<State> = {
  state: State;

  options?: {
    defaultActionType?: 'push' | 'replace';

    beforeSyncQueryParams?: (
      state: State,
      params: {
        location: BrowserHistory['location'];
        history: BrowserHistory;
      }
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      paramsState: any;
      actionType?: 'push' | 'replace';
    };

    afterSyncQueryParams?: (params: {
      location: BrowserHistory['location'];
      history: BrowserHistory;
    }) => void;

    listeners?: HistoryEventListeners;
  };
};

export const useListenHistoryEvent = (listeners: HistoryEventListeners) => {
  const history = getAppHistory();

  const listenersRef = useLatest(listeners, true);

  useMountEffect(() => {
    const paramsState = getQueryParamsState();

    castArray(listenersRef.current.onInit || []).forEach((listener) => {
      listener({ location: history.location, history: history, paramsState });
    });

    castArray(listenersRef.current.all || []).forEach((listener) => {
      listener({ location: history.location, history: history, paramsState });
    });

    const unListen = history.listen(({ action }) => {
      if (!listenersRef.current) return;
      const paramsState = getQueryParamsState();

      castArray(listenersRef.current.all || []).forEach((listener) => {
        listener({ location: history.location, history: history, paramsState });
      });

      switch (action) {
        case Action.Pop:
          castArray(listenersRef.current.onPop || []).forEach((listener) => {
            listener({ location: history.location, history, paramsState });
          });
          break;
        case Action.Push:
          castArray(listenersRef.current.onPush || []).forEach((listener) => {
            listener({ location: history.location, history, paramsState });
          });
          break;
        case Action.Replace:
          castArray(listenersRef.current.onReplace || []).forEach((listener) => {
            listener({ location: history.location, history, paramsState });
          });
          break;

        default:
          break;
      }
    });

    return () => {
      unListen();
    };
  });
};

/**
 * A React hooks for sync
 */
export const useSyncQueryParams = <State extends Dict>({
  state,
  options = {
    defaultActionType: 'push',
  },
}: SyncQueryParamsProps<State>) => {
  const history = getAppHistory();

  const beforeSyncQueryParams = useLatest(options.beforeSyncQueryParams, true);
  const afterSyncQueryParams = useLatest(options.afterSyncQueryParams, true);
  const isSyncBlocking = useRef(false);

  useListenHistoryEvent(options.listeners || {});

  useLayoutEffect(() => {
    if (!isSyncBlocking.current) {
      const { actionType = 'push', paramsState } = beforeSyncQueryParams.current
        ? beforeSyncQueryParams.current(state, { history, location: history.location })
        : {
            actionType: options.defaultActionType,
            paramsState: state,
          };

      const currentParamsState = getQueryParamsState();
      const searchString = `?${stringify(
        { ...currentParamsState, ...paramsState },
        {
          indices: false,
        }
      )}`;

      history[actionType](searchString);

      if (afterSyncQueryParams.current) {
        afterSyncQueryParams.current({ location: history.location, history });
      }
    }
  }, [state, options.defaultActionType]);

  const blockSyncAction = useCallback((action: () => unknown) => {
    isSyncBlocking.current = true;

    flushSync(() => {
      action();
    });

    isSyncBlocking.current = false;
  }, []);

  return {
    querystring: history.location.search,
    blockSyncAction,
  };
};
